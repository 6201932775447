// Siemens font import
@import '@simpl/fonts/dist/styles/siemens-fonts';

@import '@simpl/element-theme/src/theme';

// Import Element Components
@import '@simpl/element-ng/simpl-element-ng';

@import '@siemens/ngx-datatable/themes/bootstrap';

.si-about-content {
  pre {
    white-space: pre-wrap;
    word-break: unset;
    word-wrap: break-word;
  }
}

// Theme styling
html,
body {
  height: 100%;
}

body {
  font-family: 'Siemens Sans', sans-serif;
  margin: 0;
}

body,
app-root,
app-root > * {
  display: flex;
  flex: auto;
  flex-direction: column;
}

router-outlet {
  display: none !important; // stylelint-disable-line declaration-no-important
}

body {
  overscroll-behavior: none;
}

si-example-overview {
  .component-list-header,
  .preview-header {
    box-sizing: border-box;
  }
}

.ngx-datatable.scroll-vertical .datatable-body {
  overflow-y: auto;
  overflow-x: auto;
}
